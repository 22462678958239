import React, {memo} from "react";
import dynamic from 'next/dynamic'
import {useRouter} from "next/router";
import cls from "./blocks.module.scss"
import Script from "next/script";
import {schemaProducts} from "../schemaorg";

const ProductCard = dynamic(() => import("../shared/ProductCard"))
const BlockHeaderCustom = dynamic(() => import("../shared/BlockHeaderCustom"))
const InStockProducts = dynamic(() => import("../shared/InStockProducts"))

function BlockProducts(props: any) {
    const {customer, title, layout = "large-first", featuredProduct, products = [], addInClass, dbName} = props;
    const router = useRouter();

    let large = null;
    let smalls = null;

    const schema = schemaProducts(router.asPath, products, dbName)

    if (featuredProduct) {
        large = (
            <div className={cls["block-products__featured"]}>
                <div className="block-products__featured-item">
                    <ProductCard
                        product={featuredProduct}
                        customer={customer}
                        // @ts-ignore
                        rate={props.rate}
                        locale={props.locale}
                        dbName={dbName}
                    />
                </div>
            </div>
        );
    }
    if (products.length > 0) {
        const productsList = products.map((product: any, index: number) => {
            // @ts-ignore
            return (
                <InStockProducts key={index} qty={product?.product_inventories[0]?.qty}>
                    <div className={cls["product-card-parent"]}>
                        <div className="block-products__list-item">
                            <ProductCard
                                product={product}
                                customer={customer}
                                // @ts-ignore
                                rate={props.rate}
                                locale={props.locale}
                                dbName={dbName}
                            />
                        </div>
                    </div>
                </InStockProducts>
            );
        });
        smalls = <div className={cls["block-products__list"]}>{productsList}</div>;
    }


    function renderSmall() {
        if (products.length > 0) return (<div className={cls["block-products__list"]}>
            {products.map((product: any, index: number) => {
                // @ts-ignore
                return (
                    <InStockProducts
                        key={index}
                        qty={product?.product_inventories[0]?.qty}
                    >
                        <div key={index} style={{minHeight: 200}} className={cls["product-card-parent"]}>
                            <div className="block-products__list-item">
                                <ProductCard
                                    // @ts-ignore
                                    rate={props.rate}
                                    product={product}
                                    customer={customer}
                                    locale={props.locale}
                                    dbName={dbName}
                                />
                            </div>
                        </div>
                    </InStockProducts>
                );
            })}
        </div>);
    }

    return (
        <>
            <Script
                id={schema.name}
                type="application/ld+json"
                dangerouslySetInnerHTML={{__html: JSON.stringify(schema)}}
            />

            <div className={`container ${cls["home-product-container"]}`}>

                <div>
                    {smalls ? <BlockHeaderCustom title={title}/> : ""}
                </div>

                <div className={cls["block-products__body"]}>
                    {layout === "large-first" && large}
                    {renderSmall()}
                    {layout === "large-last" && large}
                </div>
            </div>
        </>
    );
}

export default memo(BlockProducts);
